<template>
  <div class="product-expand">
    <SfButton
      v-focus
      type="button"
      class="sf-button--pure product-expand__title"
      @click="toggle(title)"
    >
      <span class="product-expand__chevron">
        <SfChevron :class="{ 'sf-chevron--top': isItemOpen }" />
      </span>
      {{ title }}
    </SfButton>
    <transition name="sf-fade">
      <div
        :class="{
          'product-expand__content--hidden': !isItemOpen,
        }"
        class="product-expand__content"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>
<script>
import { SfChevron, SfButton } from '~/components';
import { focus } from '~/utilities/directives';

export default {
  name: 'ProductExpandItem',
  directives: {focus},
  components: {
    SfChevron,
    SfButton,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  inject: ['items'],
  data() {
    return {
      isItemOpen: false,
    };
  },
  watch: {
    '$parent.isOpen': {
      handler(newVal) {
        this.isItemOpen = newVal.includes(this.title);
      },
    },
  },
  created() {
    this.items.push(this.title);
  },
  methods: {
    toggle(payload) {
      this.$parent.toggle(payload);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/helpers/breakpoints";

.product-expand {

  &:not(:last-child) {
    margin-bottom: var(--sp_24);
  }

  &__title {
    width: 100%;
    height: auto;
    justify-content: start;
    text-transform: capitalize;
    font: var(--font-weight--normal) var(--font-size--base) / var(--line-height--base) var(--font-family--primary);

    &:hover, &:active {
      --box-shadow: none;
    }
  }

  &__chevron {
    margin-right: 4px;
  }

  &__content {
    display: var(--footer-column-content-display, block);
    margin-left: var(--sp_20);
    margin-top: var(--sp_16);

    @include for-desktop() {
      margin-left: 0;
    }

    @include for-desktop($ds) {
      margin-left: var(--sp_20);
    }

    &--hidden {
      --footer-column-content-display: none;
    }
  }
}
</style>

<style lang="scss">
.product-care .icon-container {
  display: flex;
  gap: var(--sp_20);
  margin-bottom: var(--sp_12);

  img {
    max-width: 40px;
  }
}
</style>
