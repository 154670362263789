<template>
  <div class="rating">
    <span>Rating</span>
    <SfIcon
        v-for="value in values"
        :class="['sf-rating__icon', { 'sf-rating__icon--active': value.label <= selectedRating || isHovered(value.label) }]"
        icon="star"
        :key="value.id"
        :value="value.id"
        @click="handleRatingClick(value.id, value.label)"
        @mouseover="handleRatingHover(value.label)"
        @mouseleave="handleRatingLeave"
    />
  </div>
</template>

<script>
import { SfIcon } from '~/components';

export default {
  name: 'Rating',
  components: {SfIcon},
  props: {
    values: {
      type: Array,
      default: [],
      required: true,
    },
  },
  data() {
    return {
      selectedRating: 0,
      hoveredRating: 0,
    };
  },
  methods: {
    handleRatingClick(id, label) {
      this.selectedRating = label;
      this.$emit('handleRatingClick', id);
    },
    handleRatingHover(label) {
      this.hoveredRating = label;
    },
    handleRatingLeave() {
      this.hoveredRating = 0;
    },
    isHovered(id) {
      return id <= this.hoveredRating;
    },
  },
};
</script>

<style lang="scss">
.rating {
  display: flex;
  align-items: center;
  --icon-size: 1rem;
  margin-bottom: var(--sp_24);

  span:first-child {
    margin-right: 1rem;
  }

  .sf-icon {
    cursor: pointer;
    transition: fill 0.3s;
  }

  .sf-rating__icon {
    fill: var(--togas-grey-100-color);
  }

  .sf-rating__icon--active {
    fill: var(--togas-gold-color);
  }
}
</style>
