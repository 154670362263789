/**
 * GraphQL Query that fetches products using received search term and the params
 * for filter, sort and pagination.
 */
export default `
  query getFacetData($search: String = "", $filter: ProductAttributeFilterInput, $pageSize: Int = 10, $currentPage: Int = 1, $sort: ProductAttributeSortInput) {
    products(search: $search, filter: $filter, pageSize: $pageSize, currentPage: $currentPage, sort: $sort) {
      items {
        __typename
        uid
        sku
        name
        short_description {
          html
        }
        badge
        tag_line
        stock_status
        only_x_left_in_stock
        product_hover_image
        product_thumbnail
        url_key
        url_rewrites {
          url
        }
        is_notify_me_enabled
        saleable_qty
        price_range {
          maximum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
          minimum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
        }
        ... on GroupedProduct {
          items {
            product {
              sku
            }
          }
        }
        ... on ConfigurableProduct {
          configurable_options {
            attribute_code
            attribute_uid
            label
            position
            uid
            use_default
            values {
              label
              saleable_qty
              sku
              uid
            }
          }
        }
      }
      page_info {
        current_page
        page_size
        total_pages
      }
      sort_fields {
        options {
          label
          value
          is_default
          direction
        }
      }
      total_count
    }
  }
`;
