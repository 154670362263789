<template>
  <carousel
    v-if="!loading"
    :settings="carouselSettings"
    :container-class="'fashion-carousel'"
    :carousel-items-qty="+banners.length"
    inside-bullets
    white-bullets
  >
    <div
      v-for="(banner, i) in banners"
      :key="banner.title"
      class="fashion-carousel__slide swiper-slide"
    >
      <div class="fashion-carousel__item-wrapper">
        <video
          v-if="banner.video_desktop_url"
          class="desktop-only"
          width="100%"
          height="100%"
          muted
          autoplay
          playsinline
          loop
          :poster="banner.video_desktop_preview_url"
        >
          <source
            type="video/mp4; codecs=&quot;avc1.42E01E, mp4a.40.2&quot;"
            :src="banner.video_desktop_url"
          />
          <track src="assets/sub.vtt" kind="captions" srclang="en">
        </video>
        <video
          v-if="banner.video_mobile_url"
          class="mobile-only"
          width="100%"
          height="100%"
          muted
          autoplay
          playsinline
          loop
          :poster="banner.video_mobile_preview_url"
        >
          <source
            type="video/mp4; codecs=&quot;avc1.42E01E, mp4a.40.2&quot;"
            :src="banner.video_mobile_url"
          />
          <track src="assets/sub.vtt" kind="captions" srclang="en">
        </video>
        <img
          v-if="!banner.video_mobile_url && banner.image_mobile_url"
          :src="banner.image_mobile_url"
          alt=""
          class="fashion-carousel__item-image mobile-only"
          height="710"
          width="412"
          :fetchpriority="i === 0 ? 'high' : 'auto'"
          :alt="banner.title"
        />
        <div class="fashion-carousel__content--wrapper">
          <div
            class="fashion-carousel__content"
            :class="banner.css_classes"
          >
            <p class="fashion-carousel__content--subtitle">{{ banner.subtitle }}</p>
            <h1
              class="fashion-carousel__content--title"
              v-html="banner.title"
            />
            <SfLink
              class="fashion-carousel__content--link swiper-no-swiping"
              :link="decodeURIComponent(localePath(formatAbsoluteUrlToLocal(banner.url, $config)))"
              :style="{ color: colorByClass(banner) }"
            >
              {{ banner.callout }}
            </SfLink>
          </div>
        </div>
      </div>
    </div>
  </carousel>
  <SkeletonLoader
    v-else
    skeleton-class="main-carousel__skeleton"
    :dark-shimmer="true"
  />
</template>
<script>
import {
  ref,
  useFetch,
  useContext,
} from '@nuxtjs/composition-api';

import { formatAbsoluteUrlToLocal } from '~/utilities/helpers';

import {
  SfLink,
  SkeletonLoader,
} from '~/components';
import Carousel from '~/components/togas/Carousel.vue';

import { PromoBannerGroupType } from '~/constants';

export default {
  name: 'FashionSlider',
  components: {
    SfLink,
    SkeletonLoader,
    Carousel,
  },
  setup() {
    const { app } = useContext();

    const banners = ref([]);
    const loading = ref(false);
    const carouselSettings = ref({
      loop: true,
      autoplay: {
        delay: 6000,
      },
      speed: 1500,
      breakpoints: {
        1024: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
        768: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
        480: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
      },
    });

    const colorByClass = (banner) => banner?.css_classes?.includes('text-white') ? 'white' : 'initial';

    useFetch(async () => {
      try {
        loading.value = true;
        const {data, errors} = await app.$vsf.$magento.api.getPromoBannerGroups(PromoBannerGroupType.FASHION_SLIDER);

        if(errors) console.warn(errors[0].message);

        banners.value = data?.promoBannerGroups?.items[0]?.items;
      }
      catch (error) {
        console.error('Error in FashionSlider.vue: ', error);
      }
      finally {
        loading.value = false;
      }
    });

    return {
      banners,
      carouselSettings,
      loading,
      colorByClass,
      formatAbsoluteUrlToLocal
    };
  }
};
</script>
<style lang="scss" scoped>
.fashion-carousel {

  &__item {
    position: relative;
  }

  &__item-image,
  &__item-video {
    display: block;
    height: auto;
    width: 100%;
  }

  &__item-image {

    @include for-desktop {
      aspect-ratio: 2 / 1.18889;
    }
  }

  &__item-wrapper {
    display: flex;
    align-items: flex-start;

    @include for-desktop {
      position: relative;
    }

    video {
      max-height: 900px;
      height: 100%;
      object-fit: cover;
    }
  }

  &__image-wrapper {
    position: relative;
    width: 100%;
  }

  &__image-wrapper--small {
    max-width: 25%;
    aspect-ratio: 2 / 3.57;
  }

  &__content {
    position: absolute;
    padding: 0 var(--sp_16);


    @include for-desktop {
      position: absolute;
      top: 90px;
      left: 30%;
      padding: 0;
    }

    @include for-desktop($dm) {
      top: 150px;
    }

    &--wrapper {
      position: absolute;
      top: 31px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      white-space: normal;
      padding: 0 var(--sp_16);
      z-index: 1;
      width: 100%;

      @include for-desktop {
        position: absolute;
        transform: none;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        max-width: var(--togas-content-max-width);
        margin: 0 auto;
        padding: 0 var(--sp_40);
        text-align: left;
      }
    }

    &--subtitle {
      font: var(--font-weight--light) 12px /13px var(--font-family--primary);
      text-align: center;
      text-transform: uppercase;
      margin: 0 0 var(--sp_8);

      @include for-desktop {
        font: var(--font-weight--normal) 12px /18px var(--font-family--primary);
        text-align: left;
      }

      @include for-desktop($dm) {
        font: var(--font-weight--light) 16 px /18px var(--font-family--display-pro);
      }
    }

    &--title {
      font: var(--font-weight--normal) 36px / 36px var(--font-family--display-pro);

      @include for-desktop {
        font: var(--font-weight--normal) 40px / 40px var(--font-family--display-pro);
        margin: 0 0 var(--sp_20);
      }
    }

    &--link {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font: var(--font-weight--normal) var(--font-size--base) / var(--line-height--base) var(--font-family--secondary);
      width: auto;
      height: 38px;
      letter-spacing: 0.05em;
      padding: var(--sp_24);

      @include for-desktop {
        font: var(--font-weight--normal) var(--font-size--base) / var(--line-height--base) var(--font-family--secondary);
        padding: var(--sp_24-m) var(--sp_24) var(--sp_24) 0;
      }
    }
  }

  &__skeleton {
    width: 100%;
    height: 80vh;
    margin-bottom: 20px;

    @include for-desktop {
      height: 70vh;
    }

    .skeleton {
      height: 100%;
    }
  }

  ::v-deep {
    .swiper-slide {
      height: fit-content;
    }
  }
}
</style>
