import { Logger } from '~/helpers/logger';
import { Cart } from '~/modules/GraphQL/types';
import { VsfContext } from '~/composables/context';

export const applyCouponsCommand = {
  execute: async (context: VsfContext, {
    currentCart,
    couponCode,
    customQuery = { applyCouponsToCart: 'applyCouponsToCart' },
    customHeaders = {},
  }) => {
    Logger.debug('[Magento]: Apply coupons on cart', {
      couponCode,
      currentCart,
    });

    const { data, errors } = await context.$magento.api.applyCouponsToCart({
      cart_id: currentCart.id,
      coupon_codes: couponCode,
      type: 'REPLACE'
    }, customQuery, customHeaders);

    Logger.debug('[Result]:', { data, errors });

    return {
      updatedCart: data.applyCouponsToCart?.cart as unknown as Cart,
      updatedCoupon: { code: couponCode },
      errors,
    };
  },
};
