<template functional>
  <span
      :class="[data.class, data.staticClass, 'sf-plus-minus']"
      :style="[data.style, data.staticStyle]"
      v-bind="data.attrs"
      v-on="listeners"
  >
    <slot>
      <span class="sf-plus-minus__bar vertical"></span>
      <span class="sf-plus-minus__bar horizontal"></span>
    </slot>
  </span>
</template>
<script>
import { focus } from '~/utilities/directives'

export default {
  name: 'SfPlusMinus',
  directives: {
    focus,
  },
}
</script>
<style lang="scss">
@import "~@/styles/helpers";

.sf-plus-minus {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 13px;
  height: 13px;
  margin: 0 var(--sp_8);
  @include for-desktop() {
    margin: 0 var(--sp_24);
  }
  cursor: pointer;

  &__bar {
    position: absolute;
    background: var(--togas-black-color);

    &.vertical {
      width: 1px;
      height: 100%;
      will-change: opacity, rotate;
    }

    &.horizontal {
      height: 1px;
      width: 100%;
    }
  }

  &.__opened {
    .vertical {
      transform: rotate(90deg);
      opacity: 0;
    }
  }
}
</style>
