<template functional>
  <transition :name="props.transition">
    <div
        v-if="props.visible"
        ref="overlay"
        :class="[data.class, data.staticClass, 'sf-overlay', { 'sf-overlay--with-before': props.withBefore }]"
        @click="listeners.click && listeners.click($event)"
    ></div>
  </transition>
</template>
<script>
export default {
  name: 'SfOverlay',
  props: {
    transition: {
      type: String,
      default: 'sf-fade',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    withBefore: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style lang="scss">
@import "~@/styles/components/atoms/SfOverlay";
</style>
