<template>
  <div
    v-if="isReady"
    class="boutiques"
  >
    <SfHeading
      class="boutiques__title"
      :level="2"
      :styleLevel="3"
      :title="boutiquesGroupMetaInfo.title"
    />
    <SfLink
      :link="localePath('/store-locator')"
      class="boutiques__subtitle"
    >
      {{ boutiquesGroupMetaInfo.callout }}
    </SfLink>
    <carousel
      :settings="carouselSettings"
      :container-class="'boutiques-carousel'"
      :carousel-items-qty="+boutiques.length"
      is-show-pagination
      is-show-navigation
      white-bullets
    >
      <SfLink
        v-for="boutique in boutiques"
        class="boutiques__item swiper-slide"
        :link="decodeURIComponent(localePath(formatAbsoluteUrlToLocal(boutique.url, $config)))"
        :key="boutique.title"
      >
        <img
          :src="boutique.image_desktop_url"
          :alt="boutique.title"
        >
        <div
          class="boutiques__content"
          :class="boutique.css_classes"
        >
          <SfHeading
            class="boutiques__content-title"
            :title="boutique.title"
            :level="2"
            :style-level="1"
          />
          <span class="boutiques__content-subtitle">{{ boutique.subtitle }}</span>
        </div>
      </SfLink>
    </carousel>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  useFetch,
  useContext,
} from '@nuxtjs/composition-api';

import {
  SfImage,
  SfLink,
  SfHeading,
  SfCarousel,
  SfCarouselItem,
  SvgImage,
  SfButton,
} from '~/components';

import { PromoBannerGroupType } from '~/constants';
import { formatAbsoluteUrlToLocal } from '~/utilities/helpers';

export default defineComponent({
  name: 'Boutiques',
  components: {
    Carousel: () => import('~/components/togas/Carousel.vue'),
    SfLink,
    SfImage,
    SfHeading,
    SfCarousel,
    SfCarouselItem,
    SvgImage,
    SfButton
  },
  setup() {
    const { app } = useContext();

    const boutiquesGroupMetaInfo = ref({});
    const boutiques = ref([]);
    const loading = ref(false);
    const carouselSettings = ref({
      loop: true,
      spaceBetween: 24,
      breakpoints: {
        1024: {
          slidesPerView: 3,
          slidesPerGroup: 1,
        },
        768: {
          slidesPerView: 2,
          slidesPerGroup: 1,
        },
        480: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
      },
    });

    const isReady = computed(() => Boolean(!loading.value && boutiques.value.length));

    useFetch(async () => {
      try {
        loading.value = true;
        const {data, errors} = await app.$vsf.$magento.api.getPromoBannerGroups(PromoBannerGroupType.BOUTIQUES);

        if(errors) console.warn(errors[0].message);

        boutiquesGroupMetaInfo.value = data?.promoBannerGroups?.items[0];
        boutiques.value = data?.promoBannerGroups?.items[0]?.items;
      }
      catch (error) {
        console.error('Error in Boutiques.vue: ', error);
      }
      finally {
        loading.value = false;
      }
    });

    const ready = ref(false);

    onMounted(() => {
      ready.value = true;
    });

    return {
      boutiquesGroupMetaInfo,
      boutiques,
      carouselSettings,
      isReady,
      formatAbsoluteUrlToLocal
    };
  },
});
</script>

<style lang="scss" scoped>
.boutiques {
  padding: var(--sp_32) 0;

  @include for-desktop {
    padding: var(--sp_40) 0;
  }

  &__title {
    --heading-color: var(--togas-white-color);
    --heading-title-font: var(--font-weight--normal) 18px / 18px var(--font-family--display-pro);
    --heading-margin: 0 0 var(--sp_4) 0;

    @include for-desktop {
      --heading-title-font: var(--font-weight--medium) 24px / 32px var(--font-family--display-pro);
    }
  }

  &__subtitle {
    display: block;
    margin: 0 auto var(--sp_16);
    width: max-content;
    font: var(--font-weight--medium) var(--font-size--base) / var(--line-height--base) var(--font-family--secondary);
    color: var(--togas-white-color);

    @include for-desktop {
      font: var(--font-weight--medium) var(--font-size--lg) / var(--line-height--lg) var(--font-family--secondary);
      --link-text-decoration: underline;
      margin: 0 auto var(--sp_24);
    }
  }

  &__item {
    position: relative;
  }

  &__content {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;

    --heading-margin: 0 0 4px 0;

    @include for-desktop($dm) {
      padding: 20px 20px 34px 20px;
    }

    &-title {
      --heading-title-font: var(--font-weight--normal) 36px / 36px var(--font-family--display-pro);

      @include for-desktop {
        --heading-title-font: var(--font-weight--normal) 40px / 40px var(--font-family--display-pro);
      }
    }

    &-subtitle {
      font: var(--font-weight--normal) var(--font-size--lg) / var(--line-height--lg) var(--font-family--secondary);

      @include for-desktop {
        font: var(--font-weight--medium) var(--font-size--lg) / var(--line-height--lg) var(--font-family--secondary);
      }
    }
  }

  ::v-deep {

    .swiper-button-prev, .swiper-button-next {
      top: 40%;
    }

    .swiper-button-next {
      right: 1%;
    }

    .swiper-button-prev {
      left: 1%;
    }
  }
}
</style>
