import { Logger } from '~/helpers/logger';
import { Cart } from '~/modules/GraphQL/types';
import { VsfContext } from '~/composables/context';

export const removeCouponsCommand = {
  execute: async (context: VsfContext, { currentCart, customQuery = { removeCouponsFromCart: 'removeCouponsFromCart' }, customHeaders = {} }) => {
    Logger.debug('[Magento]: Remove coupons from cart', { currentCart });

    const { data, errors } = await context.$magento.api.removeCouponsFromCart({
      cart_id: currentCart.id,
      coupon_codes: [],
    }, customQuery, customHeaders);

    Logger.debug('[Result]:', { data });

    return {
      updatedCart: data.removeCouponsFromCart?.cart as unknown as Cart,
      updatedCoupon: { code: '' },
      errors,
    };
  },
};
