<template>
  <div
    class="swiper-container"
    :class="containerClass"
  >
    <div class="swiper-wrapper">
      <slot />
    </div>
    <template v-if="isShowNavigation">
      <div :class="`${containerClass}-swiper-button-prev swiper-button-prev`"></div>
      <div :class="`${containerClass}-swiper-button-next swiper-button-next`"></div>
    </template>
    <div
      v-if="isShowPagination && carouselItemsQty > 1"
      :class="[
          `${containerClass}-swiper-pagination swiper-pagination`,
          { 'swiper-pagination__white': whiteBullets },
          { 'swiper-pagination__inside': insideBullets }
        ]"
      :style="{display: isCarouselInit ? 'flex' : 'none'}"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  nextTick,
  ref,
  computed,
  onMounted,
  onBeforeUnmount,
} from '@nuxtjs/composition-api';

import Swiper, { Pagination, Autoplay, Navigation } from 'swiper';

Swiper.use([Pagination, Autoplay, Navigation]);

export default defineComponent({
  name: 'Carousel',
  props: {
    containerClass: {
      type: String,
      default: '',
      required: true
    },
    settings: {
      type: Object,
      default: () => ({}),
    },
    isShowNavigation: {
      type: Boolean,
      default: false,
    },
    isShowPagination: {
      type: Boolean,
      default: true,
    },
    whiteBullets: {
      type: Boolean,
      default: false,
    },
    insideBullets: {
      type: Boolean,
      default: false,
    },
    carouselItemsQty: {
      type: Number,
    }
  },

  setup(props, {emit}) {
    const carouselRef = ref(null);
    const isCarouselInit = ref(false);

    const defaultSettings = {
      pagination: {
        el: `.${props.containerClass}-swiper-pagination`,
        type: 'bullets',
        dynamicBullets: false,
        dynamicMainBullets: 5,
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '"><span class="swiper-pagination-bullet-inner"> </span></span>';
        }
      },
      navigation: {
        nextEl: `.${props.containerClass}-swiper-button-next`,
        prevEl: `.${props.containerClass}-swiper-button-prev`,
      },
      observer: true,
      observeParents: true,
      spaceBetween: 0,
      watchOverflow: true,
      loop: false,
      threshold: 30,
      breakpoints: {
        1024: {
          slidesPerView: 3,
        },
        768: {
          slidesPerView: 2,
        },
        480: {
          slidesPerView: 1,
        },
      },
      on: {
        init: function () {
          isCarouselInit.value = true;
          emit('carousel-initialized');
        },
      },
    };

    const mergedOptions = computed(() => ({
      ...defaultSettings,
      ...props.settings,
      breakpoints: {...defaultSettings.breakpoints, ...props.settings.breakpoints},
      pagination: {
        ...defaultSettings.pagination,
        ...props.settings.pagination,
        renderBullet: props.settings.pagination?.renderBullet || defaultSettings.pagination.renderBullet,
      },
      on: {
        ...defaultSettings.on,
        ...props.settings.on,
        ...(props.settings.on && defaultSettings.on
          ? Object.keys(defaultSettings.on).reduce((acc, key) => {
            if(props.settings.on[key]) {
              acc[key] = function (...args) {
                defaultSettings.on[key].apply(this, args);
                props.settings.on[key].apply(this, args);
              };
            } else {
              acc[key] = defaultSettings.on[key];
            }
            return acc;
          }, {})
          : {}),
      },
    }));

    const initSwiper = () => {
      carouselRef.value = new Swiper(`.${props.containerClass}`, mergedOptions.value);
    };

    onMounted(() => nextTick(() => initSwiper()));

    onBeforeUnmount(() => {
      if(carouselRef.value && carouselRef.value.destroy) {
        carouselRef.value.destroy();
      }
    });

    return {
      carouselRef,
      isCarouselInit,
    };
  }
});
</script>

<style lang="scss" scoped>
@import "~swiper/swiper.scss";
</style>
