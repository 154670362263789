<template>
  <div
    class="sf-sidebar"
    :class="{ 'sf-sidebar--right': position === 'right' }"
  >
    <SfOverlay
      :visible="visibleOverlay"
      :with-before="withBefore"
      class="sidebar-overlay"
      :class="{'sidebar-overlay--line': hasLine}"
    />
    <aside
      v-if="visible && isOpen"
      ref="asideContent"
      v-focus-trap
      v-click-outside="checkPersistence"
      class="sf-sidebar__aside"
    >
      <slot name="top-additional" />
      <slot name="bar">
        <SfBar
          :isTopLevel="isTopLevel"
          :title="barTitle"
          :back="back"
          @click:back="close"
        />
      </slot>
      <template v-if="!loading">
        <div
          :class="{ 'display-none': !title || (!title && !hasTop) }"
          class="sf-sidebar__top"
        >
          <slot name="title" v-bind="{ title, subtitle, headingLevel }">
            <SfHeading
              :class="{
              'display-none': !title,
              'desktop-only': isTitleDesktopOnly
             }"
              :title="title"
              :description="subtitle"
              :level="headingLevel"
              class="
                sf-heading--left
                sf-heading--no-underline
                sf-sidebar__title
              "
            />
          </slot>
          <slot name="content-top" />
        </div>
        <div
          ref="sidebarContent"
          class="sf-sidebar__content"
        >
          <slot />
        </div>
        <div
          class="sf-sidebar__bottom"
          :class="{ 'display-none': !hasBottom }"
        >
          <slot name="content-bottom" />
        </div>
      </template>
      <template v-else>
        <SfLoader
          v-if="loading"
          class="sf-sidebar__loader" />
      </template>
    </aside>
  </div>
</template>
<script>
import { focusTrap, clickOutside } from '~/utilities/directives';
import { disableBodyScroll, clearAllBodyScrollLocks } from '~/utilities/body-scroll-lock';
import { isClient } from '~/utilities/helpers';
import { SfCircleIcon, SfBar, SfHeading, SfOverlay, SfLoader } from '~/components';

export default {
  name: 'SfSidebar',
  directives: {focusTrap, clickOutside},
  components: {
    SfBar,
    SfCircleIcon,
    SfOverlay,
    SfHeading,
    SfLoader
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    headingLevel: {
      type: Number,
      default: 3,
    },
    barTitle: {
      type: String,
      default: '',
    },
    isTopLevel: {
      type: Boolean,
      default: false,
    },
    button: {
      type: Boolean,
      default: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    overlay: {
      type: Boolean,
      default: true,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: 'right',
      validator: (value) => ['left', 'right'].includes(value),
    },
    line: {
      type: Boolean,
      default: false
    },
    back: {
      type: Boolean,
      default: true
    },
    isTitleDesktopOnly: {
      type: Boolean,
      default: true
    },
    withBefore: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'vertical-scroll-detected'],
  data() {
    return {
      transition: this.position,
      isOpen: this.visible,
      hasVerticalScroll: false,
    };
  },
  computed: {
    visibleOverlay() {
      return this.visible && this.overlay;
    },
    transitionName() {
      return 'sf-slide-' + this.transition;
    },
    hasTop() {
      return this.$slots.hasOwnProperty('content-top');
    },
    hasBottom() {
      return this.$slots.hasOwnProperty('content-bottom');
    },
    hasLine() {
      return Boolean(this.line);
    },
  },
  watch: {
    visible: {
      handler(value) {
        if(!isClient) return;
        if(value) {
          this.isOpen = true;
          this.transition = this.position;
          this.$nextTick(() => {
            const sidebarContent = document.getElementsByClassName(
              'sf-sidebar__aside'
            )[0];
            disableBodyScroll(sidebarContent);
          });
          document.addEventListener('keydown', this.keydownHandler);
        } else {
          clearAllBodyScrollLocks();
          document.removeEventListener('keydown', this.keydownHandler);
          this.isOpen = false;
        }
      },
      immediate: true,
    },
    isOpen: {
      // handle out animation for async load component
      handler(value) {
        if(!isClient) return;
        if(!value) {
          this.transition = this.position === 'right' ? 'left' : 'right';
        }
      },
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkVerticalScrollOnResize);
    clearAllBodyScrollLocks();
  },
  methods: {
    close() {
      this.isOpen = false;
      this.$emit('close');
    },
    checkPersistence(event) {
      const excludeClasses = [
        'multiselect',
        'multiselect__content-wrapper',
        'multiselect__single',
        'option__title',
        'option__image'
      ];
      const targetClassList = event.target.className;

      if(typeof targetClassList === 'string' && excludeClasses.some(cls => targetClassList.includes(cls))) {
        return;
      }

      if(!this.persistent) {
        this.close();
      }
    },
    keydownHandler(e) {
      if(e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
        this.close();
      }
    },
    showSearch() {
      // if (true) {
      //   emit('set-is-open', true);
      // }
    },
    checkVerticalScrollOnResize() {
      if(this.$refs.sidebarContent) {
        const sidebarContent = this.$refs.sidebarContent;
        if(sidebarContent.scrollHeight <= sidebarContent.clientHeight) {
          this.$emit('vertical-scroll-detected', false);
        } else if(sidebarContent.scrollHeight > sidebarContent.clientHeight) {
          this.$emit('vertical-scroll-detected', true);
        }
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.checkVerticalScrollOnResize);

    this.checkVerticalScrollOnResize();
  }
};
</script>
<style lang="scss">
@import "~@/styles/components/organisms/SfSidebar.scss";
</style>
