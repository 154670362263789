

















































































import {
  defineComponent,
  ref,
  useContext
} from '@nuxtjs/composition-api';

import {
  SfButton,
  SfCheckbox,
  SfInput,
  SfNotification,
  SfHeading,
  SfIcon
} from '~/components';

import {
  useUiNotification,
} from '~/composables';

import { clickOutside } from '~/utilities/directives';

import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
  extend,
} from 'vee-validate';

import {
  email,
  required
} from 'vee-validate/dist/rules';

setInteractionMode('eager');
extend('required', {
  ...required,
  message: 'This field is required',
});
extend('email', {
  ...email,
  message: 'Enter a correct email',
});

export default defineComponent({
  name: 'SubscribeForm',
  components: {
    SfInput,
    SfButton,
    SfCheckbox,
    ValidationProvider,
    ValidationObserver,
    SfNotification,
    SfHeading,
    SfIcon
  },
  directives: { clickOutside },
  setup(_, {refs}) {
    const { app } = useContext();
    const { send: sendNotification } = useUiNotification();

    type Form = {
      email: string,
      agreement: boolean,
    };

    const form =  ref<Form>({
      email: '',
      agreement: false
    });

    const clearForm = () =>  {
      form.value.email = '';
      form.value.agreement = false;
    };

    const icon = 'M5.58424 0.221523C5.50994 0.143862 5.40836 0.1158 5.27999 0.249981C5.23225 0.293619 5.09909 0.458931 5.02603 0.545626C4.90213 0.692636 4.91928 0.764836 5.08961 0.942887C5.25995 1.12094 13.729 9.14747 13.9198 9.34689C14.1106 9.54631 14.047 9.70778 13.9198 9.84076C13.7926 9.97375 5.2933 18.1613 5.09432 18.3693C4.89533 18.5773 4.91465 18.7131 5.02797 18.8316L5.2933 19.1089C5.38175 19.2014 5.54482 19.1522 5.67472 19.0165C5.80462 18.8807 15.0637 9.99388 15.2101 9.84076C15.3566 9.68764 15.3207 9.56341 15.2101 9.44785C15.0996 9.33228 5.65854 0.299185 5.58424 0.221523Z';
    const submitForm = (reset) => async () => {
      reset();

      try {
        const { data, errors } = await app.$vsf.$magento.api.subscribeEmailToNewsletter({ email: form.value.email });

        if (data.subscribeEmailToNewsletter?.status === 'SUBSCRIBED') {
          sendNotification({
            id: Symbol('subscribe_not_active'),
            message: app.i18n.tc('You have successfully signed up for our newsletter.'),
            type: 'success',
            icon: 'check',
            persist: false,
          });
        } else if (data.subscribeEmailToNewsletter?.status === 'NOT_ACTIVE') {
          sendNotification({
            id: Symbol('subscribe_not_active'),
            message: app.i18n.tc('You have successfully signed up for our newsletter. Please check your email to confirm your subscription'),
            type: 'success',
            icon: 'check',
            persist: false,
          });
        } else {
          sendNotification({
            id: Symbol('subscribe_error'),
            message: errors[0].message,
            type: 'info',
            icon: 'check',
            persist: false,
          });
        }

        clearForm();
      }
      catch (error) {
        console.error('Subscribe error: ', error);
      }
    };

    const resetValidation = () => {
      // @ts-ignore
      refs.formEl.reset();
    }

    return {
      form,
      submitForm,
      resetValidation,
      icon,
    };
  },
});
